import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Base from '@/pages/_base/index.vue'

Vue.use(VueRouter)

const routes = [
    // {
    //     path: '/login',
    //     name: 'login',
    //     meta:{
    //         auth: true,
    //         title: '控制台',
    //         path_name: 'login'
    //     },
    //     component: () => import('@/pages/login/index.vue')
    // },
    {
        path: '/',
        header: 'index',
        meta:{
          auth: true
        },
        component: Base,
        children: [
            {
                path: '/',
                name: 'index',
                meta:{
                    auth: true,
                    title: '控制台',
                    path_name: 'index'
                },
                component: () => import('@/pages/index/index.vue')
            },
            {
                path: '/order',
                name: 'order',
                meta:{
                    auth: true,
                    title: '订单记录',
                    path_name: 'order'
                },
                component: () => import('@/pages/user/order.vue')
            },
            {
                path: '/invite_log',
                name: 'invite_log',
                meta:{
                    auth: true,
                    title: '邀请记录',
                    path_name: 'invite_log'
                },
                component: () => import('@/pages/user/invite_log.vue')
            },
            {
                path: '/pic',
                name: 'pic',
                meta:{
                    auth: true,
                    title: '数据库',
                    path_name: 'pic'
                },
                component: () => import('@/pages/pic/pic.vue')
            },
            {
                path: '/file',
                name: 'file',
                meta:{
                    auth: true,
                    title: '文件',
                    path_name: 'file'
                },
                component: () => import('@/pages/file/index.vue')
            },
            {
                path: '/file/handle',
                name: 'handle',
                meta:{
                    auth: true,
                    title: '文件处理',
                    path_name: 'file'
                },
                component: () => import('@/pages/file/handle.vue')
            },
            {
                path: '/down/index',
                name: 'down',
                meta:{
                    auth: true,
                    title: '导出列表',
                    path_name: 'down'
                },
                component: () => import('@/pages/down/index.vue')
            },
            {
                path: '/universe/index',
                name: 'universe',
                meta:{
                    auth: true,
                    title: '星际漫游',
                    path_name: 'universe'
                },
                component: () => import('@/pages/universe/index.vue')
            },
            {
                path: '/universe/webmap',
                name: 'webmap',
                meta:{
                    auth: true,
                    title: '星际漫游',
                    path_name: 'universe'
                },
                component: () => import('@/pages/universe/webmap.vue')
            },
            {
                path: '/log',
                name: 'log',
                meta:{
                    auth: true,
                    title: '处理记录',
                    path_name: 'log'
                },
                component: () => import('@/pages/log/index.vue')
            },
            {
                path: '/help',
                name: 'help',
                meta:{
                    auth: true,
                    title: '帮助',
                    path_name: 'help',
                    keepAlive: true
                },
                component: () => import('@/pages/help/index.vue')
            },
            {
                path: '/help_details/:id',
                name: 'help_details',
                meta:{
                    auth: true,
                    title: '帮助详情',
                    path_name: 'help'
                },
                component: () => import('@/pages/help/details.vue')
            }
        ]
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
    // 获取token
    let token = store.getters.token;
    store.commit('setPath', to.meta.path_name);
    next();
    // // 权限校验
    // if(to.matched.some(record => record.meta.auth)){
    //     next();
    //     // 校验登陆状态
    //     if(token) {
    //         next();
    //     }
    //     else { // 没有登录跳转到登录界面
    //         // store.dispatch('admin/loginOut').then(()=>{
    //         //     next({
    //         //         name: 'login',
    //         //         query: {
    //         //             redirect: to.fullPath
    //         //         }
    //         //     })
    //         // });
    //         next({name: 'login'})
    //     }
    // }else{ // 不需要身份校验 直接通过
    //     // 已经登陆不允许访问登录页面
    //     if(token && to.name == 'login'){
    //         next({name: 'index'});
    //     }else{
    //         next();
    //     }
    // }
});
router.afterEach(to => {
    let config_title = '';
    if(Object.keys(store.getters.config).length) config_title = store.getters.config.website.name;
    // 更改标题
    let title = (config_title ? (config_title+' - ') : '') + to.meta.title;
    window.document.title = title;
})

export default router
