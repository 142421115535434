<template>
    <el-dialog custom-class="payDialogClass" title="购买体验卡" :visible="cardVisible" :close-on-click-modal="false" width="30%" @close="cardClose">
        <div class="card-dialog" v-loading="loading" :class="{black_mode:page_mode?true:false}">
            <div class="card_price">体验卡价格：<span>￥{{ config.experience_amount ? config.experience_amount : 0 }}</span> / 张</div>
            <div class="card_desc">{{ config.experience_desc ? config.experience_desc : '' }}</div>
            <div class="card_form">
                <el-input-number v-model="card_number" :min="1" :max="10000" label="描述文字"></el-input-number>
                <el-button class="form_btn" type="primary" @click="onBuy">立即购买</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { requestOrderCreate } from '@/api/app';
    export default {
        name: "card-dialog",
        props: {
        },
        data(){
            return {
                loading: false,
                cardVisible: false,
                card_number: 1
            }
        },
        computed:{
            ...mapGetters(['page_mode', 'config']),
        },
        created(){
        },
        methods: {
            cardOpen(){
                this.card_number = 1;
                this.cardVisible = true;
            },
            cardClose(){
                this.cardVisible = false;
            },
            // 购买
            onBuy(){
                this.loading = true;
                requestOrderCreate({type:3, card_num:this.card_number, attr:'system'}).then(res=>{
                    this.loading = false;
                    this.$emit('pay', res.data);
                    this.cardClose();
                }).catch(err =>{
                    this.loading = false;
                    if(err.status == 410000){
                        this.$store.dispatch('loginOut').then(()=>{
                            // 跳转页面
                            setTimeout(()=>{
                                window.location.reload();
                            }, 500);
                        });
                    }
                })
            }
        }
    }
</script>


<style lang="scss" scoped>
    .black_mode {
        .card_price {
            color: #FFFFFF!important;
        }
        .card_desc {
            color: #FFFFFF!important;
        }
    }
    .card-dialog {
        .card_price {
            span {
                color: #F56C6C;
                font-size: 18px;
                font-weight: bold;
            }
        }
        .card_desc {
            white-space: pre-wrap;
            margin-top: 20px;
        }
        .card_form {
            display: flex;
            align-items: center;
            margin-top: 20px;
            .form_btn {
                margin-left: 20px;
            }
        }
    }
</style>