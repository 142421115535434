import Cookies from 'js-cookie'

/**
 * 设置localStorage
 * @param {*} name 
 * @param {*} value 
 * @param {*} scope 
 */
 export const setStorage = (name, value, scope = '') => {
    // 存在作用域，拼接作用域
    if(scope){
        name = scope + '_' + name;
    }
    localStorage.setItem(name, value);
};
/**
 * 读取localStorage
 * @param {*} name 
 * @param {*} scope 
 * @returns 
 */
export const getStorage = (name, scope = '') => {
    let value = null;
    // 存在作用域，拼接作用域
    if(scope){
        name = scope + '_' + name;
    }
    let storage = localStorage.getItem(name);
    if(!storage) return value; // 没有数据返回null
    return storage;
};
/**
 * 删除指定或者清空localStorage
 * @param {*} name 
 */
export const removeStorage = (name = '') => {
    if(name){ // 存在名称
        localStorage.removeItem(name);
    }else{ // 没有，全部清除
        localStorage.clear();
    }
};

/**
 * 设置cookie
 * @param {*} key 
 * @param {*} val 
 * @param {*} cookieExpires 
 */
export const setCookies = (key, val, cookieExpires) => {
    Cookies.set(key, val, { expires: cookieExpires || 1 })
};
/**
 * 获取cookie
 * @param {*} key 
 * @returns 
 */
export const getCookies = (key) => {
    return Cookies.get(key)
};
/**
 * 清除cookie
 * @param {*} key 
 * @returns 
 */
export const removeCookies = (key) => {
    return Cookies.remove(key)
}