const Socket = function(wsSocketUrl) {
    this.ws = new WebSocket(wsSocketUrl)
    this.ws.onopen = this.onOpen.bind(this)
    this.ws.onerror = this.onError.bind(this)
    this.ws.onmessage = this.onMessage.bind(this)
    this.ws.onclose = this.onClose.bind(this)
}

Socket.prototype = {
  vm(vm) {
    this.vm = vm
  },
  close() {
    clearInterval(this.timer)
    this.ws.close()
  },
  onOpen: function() {
    this.init()
    this.vm.$emit('socket_open')
  },
  init: function() {
    var that = this
    this.loaded = true
    this.timer = setInterval(function() {
      that.send({ type: 'ping' })
    }, 30000)
  },
  send: function(data) {
    return this.ws.send(JSON.stringify(data))
  },
  onMessage: function(res) {
    let data = JSON.parse(res.data)
    this.vm.$emit('socket_receive', data)
  },
  onClose: function() {
    clearInterval(this.timer)
    this.vm.$emit('socket_close')
  },
  onError: function(e) {
    this.vm.$emit('socket-error', e)
  }
}

Socket.prototype.constructor = Socket

export default Socket