<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    import { requestGetConfig } from '@/api/app'
    export default {
        data () {
            return {}
        },
        components: {
        },
        watch: {
        },
        created(){
            requestGetConfig().then(res=>{
                this.$store.commit('setConfig', res.data);
                if(res.data.website.name){
                    let title = this.$route.meta.title
                    window.document.title = res.data.website.name + ' - ' + title;
                }
            });
        },
        mounted () {
        },
        methods: {
        }
    }
</script>

<style lang="scss">
    @import "@/static/css/style.scss";
    html, body, #app {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    body {
        font-size: $com-font-size-primary;
    }
    .black_mode {
        .file-dialog {
            background-color: #535353!important;
            .el-dialog__header .el-dialog__title {
                color: #FFFFFF!important;
            }
        }
        .payDialogClass {
            background-color: #323232!important;
            .el-dialog__title {
                color: #FFFFFF!important;
            }
        }
    }
</style>
