<template>
    <div class="component-loading" @click="onHandle">
        <img src="../../../static/images/loading.gif" />
    </div>
</template>
<script>
    export default {
        name: 'tool',
        props: {
            handle_type: {
                default: 1,
                typeof: Number
            }
        },
        methods: {
            // 点击事件
            onHandle(){
                this.$emit('onHandle');
            }
        }
    }
</script>
<style lang="scss" scoped>
    .component-loading {
        position: fixed;
        top: calc(100vh - 40%);
        right: 20px;
        width: 80px;
        height: 80px;
        background-color: #409EFF;
        border-radius: 80px;
        box-shadow: 0px 0px 10px rgba(0,0,0, 0.2);
        cursor: pointer;
        img {
            width: 70px;
            height: 70px;
            border-radius: 80px;
            padding: 5px;
        }
    }
</style>