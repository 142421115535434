//如果独立站点部署请配置新的URL

//整站接口请求地址
let API_URL = ''
// api socket 通信地址
let WS_API_URL = ''
// socket ping时间
let WS_API_PING = 10000

if (process.env.NODE_ENV === 'development') { // 测试环境
    WS_API_URL = 'wss://jl.interestar.net';
    API_URL = 'https://jl.interestar.net/api'
    // WS_API_URL = 'ws://mx.jl.devit.top';
    // API_URL = `http://mx.jl.devit.top/api`;
    // API_URL = `http://www.qpjl.co/api`;
}else{ // 生产环境请求接口地址 如果没有配置自动获取当前网址路径，如果独立部署请自行配置请求URL
    // API_URL = `http://mx.jl.devit.top/api`;
    // WS_API_URL = 'ws://mx.jl.devit.top';
    API_URL = 'https://jl.interestar.net/api'
    WS_API_URL = 'wss://jl.interestar.net';
}

const Setting = {
    // 接口请求地址
    apiBaseUrl: API_URL,
    // apisocket连接
    apiSocketUrl: WS_API_URL,
    // socket ping时间
    apiSocketPing: WS_API_PING,
}

export default Setting
