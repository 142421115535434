<template>
    <div class="login-dialog" :class="{black_mode:page_mode?true:false}" v-if="show">
        <div class="login_win">
            <div class="login_close" @click="close"><i class="el-icon-close"></i></div>
            <div class="login_account">
				<div class="account_form">
                    <div class="account_logo">
                        <el-image class="login_img" :src="logo" fit="cover"></el-image>
                        <div class="account_type" v-if="login_type == 1" @click="onChangType(3)">切换微信扫码登录</div>
                        <div class="account_type" v-if="login_type == 3" @click="onChangType(1)">切换账号密码登录</div>
                    </div>
                    <template v-if="login_type == 1">
                        <div class="account_title">欢迎使用</div>
                        <div class="account_name">使用账号登陆</div>
                        <el-form class="login_form width-prop-100" ref="formData" :rules="formRules" :model="formData">
                            <el-form-item prop="username">
                                <el-input v-model="formData.username" prefix-icon="el-icon-user-solid" placeholder="请输入用户名"></el-input>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input type="password" v-model="formData.password" prefix-icon="el-icon-lock" placeholder="请输入密码" clearable></el-input>
                            </el-form-item>
                            <el-form-item>
                                <div class="login_btns">
                                    <el-button class="login_btn" type="primary" :loading="loadding" @click="formSubmit">登录</el-button>
                                    <el-button class="close_btn" type="info" :loading="loadding" @click="close">取消</el-button>
                                </div>
                            </el-form-item>
                        </el-form>
                    </template>
                    <template v-else-if="login_type ==2">
                        <div class="account_title">二次验证</div>
                        <div class="account_name">本次登录IP与上次不一致，请进行验证</div>
                        <el-form class="login_form width-prop-100" ref="formData" :rules="formRules" :model="formData">
                            <el-form-item prop="username">
                                <el-input v-model="formData.username" prefix-icon="el-icon-user-solid" disabled></el-input>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input type="text" v-model="formData.code" prefix-icon="el-icon-cpu" placeholder="请输入验证码" clearable>
                                    <el-button slot="append" @click="getVerify" :disabled="verify_disabled">{{ verify_text }}</el-button>
                                </el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button class="width-max" type="primary" :loading="loadding" @click="formSubmit">验证</el-button>
                            </el-form-item>
                        </el-form>
                    </template>
                    <template v-else>
                        <div class="account_title">欢迎使用</div>
                        <div class="account_name">微信扫码登陆</div>
                        <div class="account_scan">
                            <div class="login_code" ref="loginCode" id="loginCode" v-loading="scan_loading"></div>
                            <div class="login_status" v-if="scan_status == 1">扫码成功</div>
                            <div class="login_status" v-if="scan_status == 2">登录成功</div>
                            <div class="login_status" v-if="scan_status == 3" @click="onScanReload">取消登录，点击刷新</div>
                        </div>
                    </template>
				</div>
			</div>
            <!-- <div class="login_weixin"></div> -->
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import QRCode from 'qrcodejs2';
    import { requestAccountLogin, requestGetUserScan, requestUserScanLogin, requestAccountGetCode, requestAccountVerify } from '@/api/app';
    export default {
        name: "login-dialog",
        data(){
            return  {
                show: false,
                logo: '',
                loadding: false,
                formData: {
                    username: '',
                    password: '',
                    verify: '',
                },
                formRules: {
                    username: [
                        { required: true, message: '请输入用户名', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' }
                    ]
                },
                login_type: 1,
                verify_time: 60,
                verify_text: '获取验证码',
                verify_disabled: false,
                scan_loading: false,
                scan_id: null,
                scan_code: '',
                scan_status: 0,
            }
        },
        created() {
            // document.onkeydown = (e) => {
            //     if (!this.loadding && window.event.keyCode === 13) {
            //         this.formSubmit();
            //     }
            // };
        },
        computed:{
            ...mapGetters(['page_mode']),
        },
        methods: {
            // 打开
            open(){
                this.show = true;
                // 设置logo
                if(!this.logo) this.logo = this.$store.getters.config.website.login_logo;
            },
            // 关闭
            close(){
                this.show = false;
                setTimeout(()=>{
                    this.login_type = 1;
                    this.formData = {
                        username: '',
                        password: '',
                        verify: '',
                    };
                }, 500);
            },
            // 切换登陆方式
            onChangType(type){
                this.login_type = type;
                if(this.login_type == 3){ // 扫码登陆
                    // 生成登录二维码
                    this.creatLoginCode();
                }
            },
            // 刷新二维码
            onScanReload(){
                this.scan_id = null;
                this.scan_status = 0;
                // 生成登录二维码
                this.creatLoginCode();
            },
            // 获取登录二维码
            creatLoginCode(){
                this.scan_loading = true;
                if(!this.scan_id){
                    requestGetUserScan().then(res=>{
                        this.scan_id = res.data.id;
                        this.scan_code = res.data.code;
                        // 生成二维码
                        this.bindQRCode();
                    });
                }else{
                    // 生成二维码
                    setTimeout(()=>{
                        this.bindQRCode();
                    }, 200);
                }
            },
            // 生成二维码
            bindQRCode() {
                this.scan_loading = false;
                this.$refs.loginCode.innerHTML = '';
                new QRCode(this.$refs.loginCode, {
                    text: this.scan_code,
                    width: 240,
                    height: 240,
                    colorDark: "#333333", // 二维码颜色
                    colorLight: "#ffffff", // 二维码背景色
                    correctLevel: QRCode.CorrectLevel.L // 容错率，L/M/H
                })
                // 请求登陆数据
                this.requestScanLogin();
            },
            // 请求登陆数据
            requestScanLogin(){
                requestUserScanLogin({id:this.scan_id}).then(res=>{
                    let data = res.data;
                    this.scan_status = data.status;
                    if(data.status == 2){
                        // 登陆成功
                        this.loginSuccess(data);
                    }else{
                        if(this.login_type == 3){
                            setTimeout(()=>{
                                this.requestScanLogin();
                            }, 1000);
                        }
                    }
                });
            },
            // 获取验证码
            getVerify(){
                requestAccountGetCode(this.formData).then(res=>{
                    this.$utils.showMsg(res.message);
                    this.verify_disabled = true;
                    this.verifyTimeOut();
                }).catch(err => {
                    this.loadding = false;
                });
            },
            // 倒计时
            verifyTimeOut(){
                this.verify_time--;
                if(this.verify_time){
                    this.verify_text = this.verify_time+'s后重新获取';
                    setTimeout(()=>{
                        this.verifyTimeOut();
                    }, 1000);
                }else{
                    this.verify_time = 60;
                    this.verify_disabled = false;
                    this.verify_text = '重新获取';
                }
            },
            // 登录
            formSubmit(){
                if(this.login_type == 1){ // 登陆
                    this.$refs['formData'].validate((valid) => {
                        if (valid) {
                            this.loadding = true;
                            requestAccountLogin(this.formData).then(res => {
                                this.loadding = false;
                                if(res.data.is_verify){ // 二次验证
                                    this.login_type = 2;
                                }else{
                                    this.$utils.showMsg(res.message);
                                    // 登陆成功
                                    this.loginSuccess(res.data);
                                }
                            }).catch(err => {
                                this.loadding = false;
                            });
                        }
                    })
                }else{ // 二次验证
                    this.loadding = true;
                    requestAccountVerify(this.formData).then(res => {
                        this.loadding = false;
                        this.$utils.showMsg(res.message);
                        // 登陆成功
                        this.loginSuccess(res.data);
                    }).catch(err => {
                        this.loadding = false;
                    });
                }
            },
            // 登陆成功
            loginSuccess(data){
                this.$utils.showMsg('登陆成功');
                this.$store.dispatch('login', {token:data.token, user_info:data.user_info}).then(()=>{
                    // 跳转页面
                    setTimeout(()=>{
                        window.location.reload();
                    }, 500);
                });
            }
        }
    }
</script>

<style lang="scss">
    .black_mode {
        .login_win {
            background-color: #272727 !important;
        }
        .account_title, .account_type {
            color: #FFFFFF !important;
        }
        .login_close {
            i {
                color: #FFFFFF !important;
            }
        }
    }
    .login-dialog {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.6);
        z-index: 999;
        .login_win {
            position: absolute;
            top: calc(50% - 270px);
            left: calc(50% - 300px);
			z-index: 999;
            width: 600px;
            height: 540px;
            background-color: #FFFFFF;
            border-radius: 6px;
            .login_close {
                position: absolute;
                top: 10px;
                right: 10px;
                i {
                    font-size: 20px;
                    cursor: pointer;
                }
            }
			.login_account {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-between;
                .account_form {
				    width: 600px;
                    padding: 40px 20px;
                    box-sizing: border-box;
                    .account_logo {
                        display: flex;
                        justify-content: space-between;
                        .login_img {
                            width: 80px;
                            height: 80px;
                        }
                        .account_type {
                            font-weight: bold;
                            font-size: 16px;
                            cursor: pointer;
                        }
                    }
                    .account_title {
                        font-size: 30px;
                        text-align: center;
                        color: $com-color-primary;
                        letter-spacing: 10px;
                        margin-top: 10px
                    }
                    .account_weixin {
                        width: 50px;
                        height: 50px;
                        margin: 20px auto;
                        cursor: pointer;
                    }
                    .account_name {
                        color: $com-color-minor;
                        text-align: center;
                        margin-top: 20px;
                    }
                    .login_form {
                        width: 300px;
                        margin: 20px auto;
                        .login_btns {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .login_btn {
                                width: 70%;
                            }
                            .close_btn {
                                width: 26%;
                            }
                        }
                    }
                    .account_scan {
                        width: 260px;
                        height: 260px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 20px auto;
                        position: relative;
                        .login_code {
                            width: 100%;
                            height: 100%;
                            padding: 10px;
                            box-sizing: border-box;
                            background-color: #FFFFFF;
                        }
                        .login_status {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #333333;
                            text-align: center;
                            font-size: 18px;
                            font-weight: bold;
                            background-color: rgba(255, 255, 255, 0.8);
                        }
                    }
                }
			}
        }
        /deep/.el-input-group__append .el-button {
            background-color: #1890ff !important;
            border-radius: 0 4px 4px 0;
            color: #FFFFFF;
        }
    }
</style>