<template>
    <div class="pay-dialog" v-loading="loading" :class="{black_mode:page_mode?true:false}">
        <div class="pay_left">
            <div class="pay_code" v-loading="scanLoad">
                <div class="weixin_pay_code" ref="payCode" id="payCode" v-if="type=='weixin'"></div>
                <iframe class="alipay_pay_iframe" :srcdoc="alipayCode" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no" style="overflow:hidden;" v-else></iframe>
            </div>
            <div class="pay_type">
                <div class="type_item" :class="{'type_active':type=='weixin'}" @click="onTabPay('weixin')">微信支付</div>
                <div class="type_item" :class="{'type_active':type=='alipay'}" @click="onTabPay('alipay')">支付宝</div>
            </div>
        </div>
        <div class="pay_details">
            <div class="details_item">
                <div class="item_title">订单类型：</div>
                <div class="item_content">{{ details.type_text }}</div>
            </div>
            <div class="details_item">
                <div class="item_title">订单编号：</div>
                <div class="item_content">{{ details.sn }}</div>
            </div>
            <div class="details_item">
                <div class="item_title">订单金额：</div>
                <div class="item_content item_price">￥{{ details.amount }}</div>
            </div>
            <template v-if="details.type == 1">
                <div class="details_item">
                    <div class="item_title">等级名称：</div>
                    <div class="item_content">{{ details.group ? details.group.name : '' }}</div>
                </div>
                <div class="details_item">
                    <div class="item_title">等级时长：</div>
                    <div class="item_content">{{ details.group_type_text }}</div>
                </div>
            </template>
            <template v-if="details.type == 2">
                <div class="details_item">
                    <div class="item_title">数据名称：</div>
                    <div class="item_content">{{ details.pic ? details.pic.name : '' }}</div>
                </div>
            </template>
            <template v-else>
                <div class="details_item">
                    <div class="item_title">购买数量：</div>
                    <div class="item_content">{{ details.card_num }}张</div>
                </div>
                <div class="details_item">
                    <!-- <div class="item_title">数据名称：</div> -->
                    <div class="item_content" style="white-space: pre-wrap;">{{ config.experience_desc ? config.experience_desc : '' }}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2';
    import { requestGetOrderDetails, requestGetOrderPay } from '@/api/app';
    import { mapGetters } from 'vuex';
    export default {
        name: "pay-dialog",
        props: {
            id: {
                default: null
            }
        },
        data(){
            return {
                details: {},
                loading: false,
                scanLoad: false,
                type: 'weixin',
                weixinCode: '',
                alipayCode: '',
            }
        },
        watch: {
            id:{
                immediate: true,
                handler(){
                    // 获取订单信息
                    this.getDetails();
                }
            }
        },
        computed:{
            ...mapGetters(['page_mode', 'config']),
        },
        methods: {
            // 切换支付方式
            onTabPay(type){
                if(type != this.type){
                    this.type = type;
                    if(this.type == 'weixin' && this.weixinCode){
                        setTimeout(()=>{
                            this.bindQRCode();
                        }, 100);
                    }
                    else if(this.type == 'alipay' && this.alipayCode){
                        return false;
                    }else{
                        // 获取支付数据
                        this.getPay();
                    }
                }
            },
            // 获取详情数据
            getDetails(){
                this.loading = true;
                requestGetOrderDetails(this.id).then(res=>{
                    this.loading = false;
                    this.details = res.data;
                    // 获取支付数据
                    this.getPay();
                })
            },
            // 获取支付数据
            getPay(){
                this.scanLoad = true;
                requestGetOrderPay(this.id, this.type).then(res=>{
                    let uri = res.data.uri;
                    if(this.type == 'weixin'){
                        this.weixinCode = uri;
                        this.bindQRCode();
                    }else{
                        this.alipayCode = uri;
                    }
                    this.scanLoad = false;
                })
            },
            // 生成二维码
            bindQRCode() {
                this.$refs.payCode.innerHTML = '';
                new QRCode(this.$refs.payCode, {
                    text: this.weixinCode,
                    width: 300,
                    height: 300,
                    colorDark: "#333333", // 二维码颜色
                    colorLight: "#ffffff", // 二维码背景色
                    correctLevel: QRCode.CorrectLevel.L // 容错率，L/M/H
                })
            }
        }
    }
</script>


<style lang="scss" scoped>
    .black_mode {
        .pay_details {
            .details_item {
                color: #FFFFFF!important;
            }
        }
    }
    .pay-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 600px;
        .pay_left {
            width: 400px;
            height: 440px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 30px;
            .pay_code {
                width: 400px;
                height: 400px;
                align-items: center;
                justify-content: center;
                .weixin_pay_code {
                    width: 300px;
                    height: 300px;
                    padding: 6px;
                    border-radius: 4px;
                    border: 1px solid #e4e7ed;
                    margin: 50px;
                }
                .alipay_pay_iframe {
                    width: 300px;
                    height: 300px;
                    padding: 6px;
                    border-radius: 4px;
                    border: 1px solid #e4e7ed;
                    margin: 50px;
                }
            }
            .pay_type {
                display: flex;
                justify-content: center;
                .type_item {
                    width: 80px;
                    height: 28px;
                    line-height: 28px;
                    text-align: center;
                    border-radius: 4px;
                    border: 1px solid #e4e7ed;
                    cursor: pointer;
                    margin-left: 20px;
                    &:first-child {
                        margin-left: 0;
                    }
                }
                .type_active {
                    color: #409EFF;
                    border: 1px solid #409EFF;
                }
            }
        }
        .pay_details {
            width: 320px;
            height: 440px;
            padding-left: 30px;
            border-left: 2px solid #e4e7ed;
            .details_item {
                display: flex;
                font-size: 18px;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                color: #010101;
                .item_title {
                    font-weight: bold;
                }
                .item_price {
                    font-weight: bold;
                    color: #F56C6C;
                }
            }
        }
    }
</style>