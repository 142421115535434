<template>
    <div class="main-base" :class="{black_mode:page_mode?true:false}">
        <div class="base_header">
            <div class="header_logo"><el-image class="global_img" :src="logo" fit="cover "></el-image></div>
            <div class="header_tips" v-if="tips_time">
                <el-carousel indicator-position="none" direction="vertical" height="60px" :interval="tips_time">
                    <el-carousel-item v-for="item in tips_list">
                        <div class="tips_item">{{ item.title }}</div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="header_account">
                <el-dropdown @command="handleUser">
                    <div class="account_user">
                        <div class="user_avatar">
                            <img class="avatar_img" :src="user_info ? user_info.avatar : default_avatar" />
                            <img class="avatar_pendant" src="@/static/images/user_vip_pendant.png" />
                        </div>
                        <div class="user_name">{{ user_info ? user_info.nickname : '立即登录' }}</div>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item icon="el-icon-user" command="user_personal">会员中心</el-dropdown-item> -->
                        <!-- <el-dropdown-item icon="el-icon-user" command="user_order">订单记录</el-dropdown-item> -->
                        <el-dropdown-item icon="el-icon-moon" command="page_mode" v-if="!page_mode">深色模式</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-sunny" command="page_mode" v-else>普通模式</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-time" command="invite_log" v-if="token">邀请记录</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-switch-button" divided command="login_out" v-if="token">退出登录</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-warning-outline" divided command="login_out" v-else>立即登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-tooltip class="item" effect="dark" :content="useQuota" placement="bottom">
                    <div class="header_progress">
                        <div class="progress_title">容量：</div>
                        <el-progress class="progress_item" :percentage="progressBili" :format="progressText" :text-inside="true" :stroke-width="16"></el-progress>
                    </div>
                </el-tooltip>
                <div class="header_info" v-if="user_info">光子：{{ user_info ? user_info.gz_number : 0 }}</div>
                <div class="header_info" style="cursor: pointer;" @click="onPayCard" v-if="user_info">体验卡：{{ user_info ? user_info.card_num : 0 }}张 <span>购买</span></div>
            </div>
        </div>
        <div style="height:60px;"></div>
        <div class="base_frame">
            <div class="base_menu">
                <div class="menu_top">
                    <div class="menu_item" :class="{menu_active:menuActive=='index'}" @click="onJump('index')">
                        <i class="item_icon el-icon-set-up"></i>
                        <div class="item_name">控制台</div>
                    </div>
                    <div class="menu_item" :class="{menu_active:menuActive=='pic'}" @click="onJump('pic')">
                        <i class="item_icon el-icon-picture-outline"></i>
                        <div class="item_name">数据库</div>
                    </div>
                    <div class="menu_item" :class="{menu_active:menuActive=='file'}" @click="onJump('file')">
                        <i class="item_icon el-icon-folder-opened"></i>
                        <div class="item_name">文件</div>
                    </div>
                    <div class="menu_item" :class="{menu_active:menuActive=='log'}" @click="onJump('log')">
                        <i class="item_icon el-icon-time"></i>
                        <div class="item_name">处理记录</div>
                    </div>
                    <div class="menu_item" :class="{menu_active:menuActive=='down'}" @click="onJump('down')">
                        <i class="item_icon el-icon-printer"></i>
                        <div class="item_name">导出列表</div>
                    </div>
                    <div class="menu_item" :class="{menu_active:menuActive=='universe'}" @click="onJump('universe')">
                        <i class="item_icon el-icon-magic-stick"></i>
                        <div class="item_name">星际漫游</div>
                    </div>
                </div>
                <div class="menu_bottom">
                    <div class="menu_item" :class="{menu_active:menuActive=='help'}" @click="onJump('help')">
                        <i class="item_icon el-icon-info"></i>
                        <div class="item_name">帮助</div>
                    </div>
                </div>
            </div>
            <div class="base_page"><router-view ref="chieldPage" /></div>
        </div>
        <login-dialog ref="login"></login-dialog>
        <!-- <handle-loading :handle_type="handle_type" @onHandle="onHandle" v-if="handle_load"></handle-loading> -->
        <el-dialog custom-class	="payDialogClass" :title="payTitle" :visible="payVisible" :close-on-click-modal="false" width="50%" @close="payClose">
            <pay-dialog :id="pay_order_id"></pay-dialog>
        </el-dialog>
        <card-buy ref="card_buy" @pay="cardPay"></card-buy>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import Setting from '@/setting';
    import Socket from '@/libs/socket';
    import HandleLoading from './components/handle_loading'
    import LoginDialog from '@/components/login-dialog/login-dialog'
    import CardBuy from '@/components/card-buy/card-buy'
    import PayDialog from '@/components/pay-dialog/pay-dialog'
    import { requestGetCenter, requestGetTipList } from '@/api/app';
    export default {
        data(){
            return {
                logo: '',
                default_avatar: '',
                menuActive: '',
                socket: '',
                socketRec: 0,
                handle_load: false,
                handle_id: 0,
                handle_type: 1,
                token_close: false,
                tips_list: [],
                payTitle: '订单支付',
                payVisible: false,
                pay_order_id: null,
                tips_time: null
            }
        },
        components:{
            HandleLoading,
            LoginDialog,
            PayDialog,
            CardBuy
        },
        mounted(){
            // 设置logo
            this.setLogo()
            this.menuActive = this.path_name;
            let that = this;
            // 连接socket
            this.createSocket();
            // 收到消息，处理
            this.$on('socket_receive', res=>{
                try{
                    let data = res.data;
                    switch(res.type){
                        case 'upload': // 图片上传
                            that.$refs.chieldPage.uploadFeedback(data);
                            break;
                        case 'handle_notice': // 文件处理通知
                            if(data.status == 1){ // 处理中
                                this.handle_id = data.handle_id;
                                this.handle_type = 1;
                                this.handle_load = true;
                                that.$refs.chieldPage.handleOngoing(data);
                            }
                            else if(data.status == 2){ // 处理完成
                                this.handle_id = data.handle_id;
                                this.handle_type = 2;
                                this.handle_load = true;
                                this.$utils.showMsg('文件处理成功');
                                that.$refs.chieldPage.handleFinish(data);
                            }
                            else if(data.status == 3){ // 处理结束
                                this.handle_load = false;
                                that.$refs.chieldPage.handleFinish(data);
                            }
                            else if(data.status == 4){ // 处理失败
                                console.log(res.type, data);
                                this.handle_load = false;
                                this.$utils.showMsg('文件处理失败', 'error');
                                that.$refs.chieldPage.handleFail(data);
                            }
                            break;
                        case 'export_success': // 图片上传
                            this.$utils.showMsg('文件导出处理成功，请前往导出列表查看');
                            break;
                        case 'refresh_user': // 更新用户信息
                            // 更新用户信息
                            requestGetCenter().then(res=>{
                                this.$store.commit('setUserInfo', res.data);
                            });
                            break;
                        case 'token_close': // token校验失败
                            this.token_close = true;
                            break;
                        case 'pay_success': // 支付成功
                            this.$refs.chieldPage.pay_success(res.data);
                            // 支付成功
                            this.pay_success(res.data);
                            break;
                    }
                }catch(err){};
            });
            // 断开连接
            this.$on('socket_close', res=>{
                console.log('socket_close', res);
                if(!this.token_close){
                    if(this.socketRec < 3){
                        this.$confirm('连接已断开, 是否重连?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.socketRec += 1;
                            that.createSocket();
                        }).catch();
                    }else{
                        this.$confirm('重连失败', '提示', {
                            confirmButtonText: '确定',
                            showCancelButton: false,
                            type: 'warning'
                        });
                    }
                }
                this.token_close = false;
            });

            requestGetTipList().then(res=>{
                this.tips_list = res.data;
            });
        },
        watch: {
            '$store.getters.path_name'(path_name){
                this.menuActive = path_name;
            },
            '$store.getters.config'(){
                // 设置logo
                this.setLogo()
                this.tips_time = parseInt(this.config.website.tips_time);
            }
        },
        computed:{
            ...mapGetters(['user_info', 'config', 'path_name', 'token', 'page_mode']),
            // 进度条比例
            progressBili(){
                if(!this.user_info) return 100;
                return parseFloat((this.user_info.use_quota / this.user_info.total_quota).toFixed(2)) * 100;
            },
            // 已使用容量
            useQuota(){
                if(!this.user_info) return '立即登录';
                return '总计容量：'+this.user_info.total_quota+'M，已使用：'+this.user_info.use_quota+'M';
            }
        },
        methods: {
            ...mapMutations(['setPageMode']),
            // 点击loadding
            onHandle(){
                if(this.handle_id && this.$route.name != 'handle'){
                    this.$router.push({
                        name: 'handle',
                        query: {
                            id: this.handle_id
                        }
                    })
                }
            },
            // 创建socket连接
            createSocket(){
                let url = Setting.apiSocketUrl + '?token=' + encodeURIComponent(this.token);
                this.socket = new Socket(url);
                this.socket.vm(this);
            },
            // 设置logo
            setLogo(){
                if(Object.keys(this.config).length){
                    if(this.page_mode){
                        this.logo = this.config.website.black_logo;
                    }
                    else {
                        this.logo = this.config.website.logo;
                    }
                    this.default_avatar = this.config.website.login_logo;
                }
            },
            // 进度条文字内容
            progressText() {
                if(!this.user_info) return '立即登录';
                return this.user_info.use_quota+'/'+this.user_info.total_quota;
            },
            // 跳转页面
            onJump(name){
                if((name=='file' || name=='log' || name=='down') && !this.$store.getters.token){// 登录
                    this.$refs.login.open();
                    return;
                }
                if(this.path_name != name){
                    this.$router.push({ name: name });
                }else{
                    this.$router.replace({ name: name });
                }
            },
            // 指令事件
            handleUser(command) {
                switch(command){
                    case 'login_out': // 退出登录
                        if(!this.user_info){
                            this.$refs.login.open();
                        }else{
                            this.$confirm('您确认退出登陆吗?', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                this.$store.dispatch('loginOut').then(()=>{
                                this.$utils.showMsg('退出成功');
                                    // 跳转页面
                                    setTimeout(()=>{
                                        window.location.href = '/';
                                        this.$router.replace({path: '/'});
                                    }, 500);
                                });
                            });
                        }
                        break;
                    case 'page_mode':
                        let mode = this.page_mode ? 0 : 1;
                        this.setPageMode(mode);
                        // 设置logo
                        this.setLogo()
                        break;
                    case 'user_personal':
                        this.$router.replace({path: '/personal'});
                        break;
                    case 'user_order':
                        this.$router.replace({path: '/order'});
                        break;
                    case 'invite_log':
                        this.$router.replace({path: '/invite_log'});
                        break;
                }
            },
            onPayCard(){
                this.$refs.card_buy.cardOpen();
            },
            cardPay(data){
                this.pay_order_id = data.order_id;
                this.payVisible = true;
            },
            // 支付关闭
            payClose(){
                this.payVisible = false;
            },
            // 支付成功
            pay_success(data){
                let order_id = data.order_id;
                if(this.pay_order_id == order_id){
                    this.$confirm('支付成功', '提示', {
                        confirmButtonText: '确定',
                        showCancelButton: false,
                        type: 'success'
                    }).then(() => {
                        // 支付关闭
                        this.payClose();
                        // 更新用户信息
                        requestGetCenter().then(res=>{
                            this.$store.commit('setUserInfo', res.data);
                        });
                    });
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .black_mode {
        .base_header {
            background-color: #535353!important;
            .account_user {
                .user_name {
                    color: #FFFFFF!important;
                }
            }
            .header_progress {
                .progress_title {
                    color: #FFFFFF!important;
                }
            }
            .header_info {
                color: #FFFFFF!important;
            }
            /deep/.el-progress-bar__outer {
                background-color: #FFFFFF!important;
            }
            /deep/.el-progress-bar__innerText {
                color: #535353!important;
            }
        }
        .base_frame {
            background-color: #272727!important;
            .base_menu {
                background-color: #535353!important;
                .menu_item {
                    color: #FFFFFF!important;
                }
                .menu_active {
                    color: #409EFF!important;
                }
            }
        }
    }
    .main-base {
        width: 100%;
        height: 100%;
        .base_header {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 60px;
            background-color: #FFFFFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            box-sizing: border-box;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
            .header_logo {
                width: 200px;
                height: 60px;
            }
            .header_tips {
                flex: 1;
                margin-left: 50px;
                .tips_item {
                    color: #FFFFFF;
                    line-height: 60px;
                    font-size: 18px;
                }
            }
            .header_account {
                width: 560px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .account_user {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    .user_avatar {
                        position: relative;
                        height: 50px;
                        display: flex;
                        align-items: flex-end;
                        .avatar_img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50px;
                            vertical-align: bottom;
                        }
                        .avatar_pendant {
                            position: absolute;
                            top: 2px;
                            left: -2px;
                            width: 50px;
                            height: 50px;
                            z-index: -1;
                            filter: grayscale(100%);
                        }
                    }
                    .user_name {
                        margin-left: 10px;
                        color: $com-font-color;
                        font-weight: bold;
                    }
                }
                .header_progress {
                    width: 200px;
                    display: flex;
                    .progress_item {
                        width: 140px;
                    }
                }
                .header_info {
                    span {
                        color: #F56C6C;
                    }
                }
            }
        }
        .base_frame {
            height: calc(100% - 60px);
            display: flex;
            .base_menu {
                width: 60px;
                height: 100%;
                background-color: #FFFFFF;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                box-shadow: 3px 0 10px rgba(0, 0, 0, 0.08);
                .menu_item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                        margin-top: 40px;
                    // &:nth-child(2n) {
                    //     margin-top: 40px
                    // }
                    .item_icon {
                        font-size: 26px;
                    }
                    .item_name {
                        font-size: 12px;
                        margin-top: 6px
                    }
                }
                .menu_active {
                    color: $com-color-primary;
                }
            }
            .base_page {
                width: calc(100% - 60px);
                height: 100%;
                overflow: auto;
                padding: 20px;
                box-sizing: border-box;
            }
        }
    }
</style>
