import axios from 'axios'
import Setting from '@/setting'
import router from '@/router'
import store from '@/store'
import utils from '@/libs/utils';
const service = axios.create({
    baseURL: Setting.apiBaseUrl,
    timeout: 30000 // 请求超时时间
})
// loadding
let Loaddings = false;

function requestBase(options){
    // loadding
    if(options.loadding) Loaddings = utils.showLoad();
    // 携带token
    const token = store.getters.token;
    const headers = options.headers || {}
    if (token) headers['Authori-zation'] = token;
    options.headers = headers
    return new Promise((resolve, reject) => {
        service(options).then(result => {
            Loaddings ? Loaddings.close() : '';
            let res = result.data;
            switch (res.status) {
                case 200:
                    return resolve(res);
                case 400:case 400011:
                    utils.showMsg(res.message, 'error');
                    return reject(res);
                case 410000:
                case 410001:
                case 410002:
                    let message = res.message || { message: '未知错误' };
                    utils.showMsg(message, 'error');
                    return reject({status:410000, message:res.message});
                default:
                    break;
            }
        }).catch(err => {
            Loaddings ? Loaddings.close() : '';
            if(!err.response){
                err.message = '服务器开小差了';
            }else{
                err.message = err.response.data.message || '未知错误';
            }
            return reject(err)
        });
    })
}

const request = ['post', 'put', 'delete'].reduce((request, method) => {
    request[method] = (url, data = {}, loadding = false) => {
        let options = {
            loadding:loadding
        }
        return requestBase(
            Object.assign({url, data, method}, options)
        )
    }
    return request
}, {});
['get'].forEach(method => {
  request[method] = (url, params = {}, loadding = false) => {
    let options = {
        loadding:loadding
    }
    return requestBase(
        Object.assign({url, params, method}, options)
    )
  }
})

export default request
