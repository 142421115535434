import { getStorage, setStorage, removeStorage  } from '@/libs/storage';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getStorage('user_token'),
    config: [],
    user_info: JSON.parse(getStorage('user_info')) ?? null,
    path_name: '',
    page_mode: (getStorage('page_mode')==0) ? false : true,
  },
  getters: {
    token: state => state.token,
    config: state => state.config,
    user_info: state => state.user_info,
    path_name: state => state.path_name,
    page_mode: state => state.page_mode,
  },
  mutations: {
    // 设置token
    setToken(state, value){
        state.token = value;
        setStorage('user_token', value);
    },
    // 设置配置
    setConfig(state, value){
        state.config = value;
    },
    // 设置会员信息
    setUserInfo(state, value){
        let obj = JSON.stringify(value) ?? '';
        state.user_info = value;
        setStorage('user_info', obj);
    },
    // 设置path
    setPath(state, value){
        state.path_name = value;
    },
    // 设置页面模式
    setPageMode(state, value){
        state.page_mode = value==0 ? false : true;
        setStorage('page_mode', value);
    },
  },
  actions: {
    // 登陆
    login({ commit }, data){
        return new Promise(resolve => {
            commit('setToken', 'Bearer ' + data.token);
            commit('setUserInfo', data.user_info);
            return resolve();
        })
    },
    // 退出登陆
    loginOut({ commit }, data){
        return new Promise(resolve => {
            removeStorage('user_token');
            removeStorage('user_info');
            return resolve();
        })
    }
  },
  modules: {
  }
})
