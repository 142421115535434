import request from '@/libs/request'

/**
 * 获取平台系统配置
 * @returns 
 */
export function requestGetConfig () {
    return request.get('/config')
}
/**
 * 获取用户信息
 * @returns 
 */
export function requestGetCenter () {
    return request.get('/center')
}
/**
 * 获取用户等级信息
 * @returns 
 */
export function requestGetMyGroupList () {
    return request.get('/user/user/group_list')
}
/**
 * 获取首页数据
 * @returns 
 */
export function requestGetIndex () {
    return request.get('/index')
}
/**
 * 获取帮助列表
 * @returns 
 */
export function requestGetHelp (params) {
    return request.get('/help/list', params)
}
/**
 * 获取帮助详情
 * @returns 
 */
export function requestGeDetails (id) {
    return request.get(`/help/details/${id}`)
}
/**
 * 帮助标签 - 帮助标签选择数据
 * @returns 
 */
export function requestGetHelpLabelSelect () {
    return request.get('/help/label/select');
}

/**
 * 用户登录
 * @returns 
 */
export function requestAccountLogin (data) {
    return request.post('account/login', data)
}
/**
 * 用户获取验证码
 * @returns 
 */
export function requestAccountGetCode (data) {
    return request.get('account/get_code', data)
}
/**
 * 用户二次验证
 * @returns 
 */
export function requestAccountVerify (data) {
    return request.post('account/verify', data)
}


/**
 * 获取当前目录列表数据
 * @returns 
 */
export function requestGetFileList (params) {
    return request.get('file/index', params)
}
/**
 * 新建文件夹
 * @returns 
 */
export function requestCreateFolder (data) {
    return request.post('file/create_folder', data)
}
/**
 * 修改文件名称
 * @returns 
 */
export function requestUpdateFile (data) {
    return request.put('file/file', data)
}
/**
 * 粘贴文件
 * @returns 
 */
export function requestMoveFile (data) {
    return request.put('file/move', data)
}
/**
 * 删除文件
 * @returns 
 */
export function requestDeleteFile (data) {
    return request.delete('file/delete', data)
}

/**
 * 处理数据列表
 * @returns 
 */
export function requestHandleList (params) {
    return request.get('handle/list', params)
}
/**
 * 重新编辑数据
 * @returns 
 */
export function requestHandleRestart (params) {
    return request.post('handle/restart', params)
}
/**
 * 删除处理数据
 * @returns 
 */
export function requestHandleDelete (id) {
    return request.delete(`handle/delete/${id}`)
}

/**
 * 处理数据详情
 * @returns 
 */
export function requestHandleDetails (params) {
    return request.get('handle/details', params)
}
/**
 * 获取处理工具
 * @returns 
 */
export function requestHandleTool () {
    return request.get('handle/tool')
}
/**
 * 创建文件处理
 * @returns 
 */
export function requestHandleFile (data) {
    return request.post('handle/file', data)
}
/**
 * 数据处理
 * @returns 
 */
export function requestHandleHandleTool (data) {
    return request.post('handle/handle_tool', data)
}
/**
 * 数据处理完成
 * @returns 
 */
export function requestHandleFinish (data) {
    return request.post('handle/handle_finish', data)
}
/**
 * 开启数据预览
 * @returns 
 */
export function requestHandleHandlePreviewStart (data) {
    return request.post('handle/handle_preview_start', data)
}
/**
 * 数据预览
 * @returns 
 */
export function requestHandleHandlePreview (data) {
    return request.post('handle/handle_preview', data)
}
/**
 * 处理数据表格表头
 * @returns 
 */
export function requestHandleHeader (params) {
    return request.get('handle/table/header', params)
}
/**
 * 处理数据表格列表
 * @returns 
 */
export function requestHandleTable (params) {
    return request.get('handle/table/table', params)
}
/**
 * 处理数据图表数据
 * @returns 
 */
export function requestHandleEcharts (params) {
    return request.get('handle/table/echarts', params)
}
/**
 * 科研探索数据
 * @returns 
 */
export function requestHandleHandleExplore (data) {
    return request.post('handle/handle_explore', data)
}
/**
 * 取消表格处理
 * @returns 
 */
export function requestHandleHandleTableClose (data) {
    return request.post('handle/table/handle_table_close', data)
}
/**
 * 取消处理
 * @returns 
 */
export function requestHandleHandleClose (data) {
    return request.post('handle/table/handle_close', data)
}
/**
 * 关闭视频消息提示
 * @returns 
 */
export function requestHandleRenderClose (data) {
    return request.post('handle/render/close', data)
}


/**
 * 处理数据列表
 * @returns 
 */
export function requestHandleDownList (params) {
    return request.get('handle/down/list', params)
}
/**
 * 图片下载
 * @returns 
 */
export function requestHandleDown (data) {
    return request.post('handle/handle_down', data)
}
/**
 * 处理数据删除
 * @returns 
 */
export function requestHandleDownDelete (id) {
    return request.delete('handle/handle_down_delete', {id:id})
}
/**
 * 图片下载
 * @returns 
 */
export function requestHandleCheckDown (params) {
    return request.get('handle/check_down', params)
}

/**
 * 数据库 - 数据库筛选数据
 * @returns 
 */
export function requestGetPicFilter() {
    return request.get('pic/filter' )
}
/**
 * 数据库 - 数据库列表数据
 * @returns 
 */
export function requestGetPicList (params) {
    return request.get('pic/list', params)
}


/**
 * 等级 - 等级列表数据
 * @returns 
 */
export function requestGetGroupList () {
    return request.get('user/group/list')
}


/**
 * 订单 - 订单列表
 * @returns 
 */
export function requestOrderGetList (params) {
    return request.get('order/list', params)
}
/**
 * 订单 - 创建订单
 * @returns 
 */
export function requestOrderCreate (params) {
    return request.post('order/create', params)
}
/**
 * 订单 - 获取订单信息
 * @returns 
 */
export function requestGetOrderDetails (id) {
    return request.get(`order/details/${id}`)
}
/**
 * 订单 - 获取订单支付
 * @returns 
 */
export function requestGetOrderPay (id, type) {
    return request.get(`order/pay/${id}/${type}`)
}


/**
 * 获取微信扫码登录
 * @returns 
 */
export function requestGetUserScan () {
    return request.get('user/scan')
}
/**
 * 微信扫码登录
 * @returns 
 */
export function requestUserScanLogin (params) {
    return request.post('user/scan_login', params)
}

/**
 * 微信扫码登录
 * @returns 
 */
export function requestGetUniverseUrl (type) {
    return request.get(`universe/url/${type}`)
}


/**
 * 绑定邀请码
 * @returns 
 */
export function requestBindInvite (params) {
    return request.post('user/bind_invite', params)
}
/**
 * 邀请记录
 * @returns 
 */
export function requestInviteLog (params) {
    return request.get('user/invite_log', params)
}

/**
 * 小贴士列表
 * @returns 
 */
export function requestGetTipList () {
    return request.get('/tips/list')
}